<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input label="用户昵称" v-model="searchParam.nickName" />
        <v-date-picker
          label="留言时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="留言开始时间"
          endPlaceholder="留言结束时间"
        />
      </template>
      <template #operateSlot="scope"
        ><v-button text="回复" type="text" @click="reply(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="title"
      v-model="show"
      @confirm="confirm"
      sureTxt="发送"
      width="50%"
      :showCancelButton="false"
      oStyle="justify-content: flex-end;"
    >
      <div class="box" @click="nowIndex = undefined">
        <div class="item" v-for="(t, i) in list" :key="i">
          <div class="time">{{ t.createTime }}</div>
          <!-- 自己 -->
          <div
            class="item-blue"
            @contextmenu.prevent="rightClick(t, i)"
            v-if="t.direction == '1'"
          >
            <div class="content">
              {{ t.leaveWordContent.text }}
              <img class="arrow" src="./img/blue-arrow.png" alt="" />
              <img
                v-if="i == nowIndex"
                class="arrow2"
                src="./img/black-arrow.png"
                alt=""
              />
              <div v-if="i == nowIndex" class="operation-box">
                <div class="txt" @click.stop="messageDel(t, i)">删除</div>
              </div>
            </div>
            <img
              class="headImg"
              :src="t.avatar || require('@/assets/img/user.png')"
              alt=""
            />
          </div>
          <!-- 对方 -->
          <div class="item-grey" @contextmenu.prevent="rightClick(t, i)" v-else>
            <img
              class="headImg"
              :src="t.avatar || require('@/assets/img/user.png')"
              alt=""
            />
            <div class="content">
              <div class="text">{{ t.leaveWordContent.text }}</div>
              <div class="imgBox" v-if="t.leaveWordContent.imgs">
                <v-upload
                  :limit="9"
                  :imgUrls.sync="t.leaveWordContent.imgs"
                  disabled
                />
              </div>
              <img class="arrow" src="./img/grey-arrow.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 最底部box -->
        <div class="bottomBox" ref="bottomBox"></div>
      </div>
      <v-input
        type="textarea"
        v-model="text"
        :textAreaRows="5"
        placeholder="请输入内容"
      >
      </v-input>
    </v-dialog>
  </div>
</template>

<script>
import {
  getInfoList,
  leaveWordsUrl,
  getDetailsListUrl,
  deleteLeaveWordUrl,
} from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "messageReply",
  data() {
    return {
      title: "用户名称",
      show: false,
      searchParam: {
        leaveWordType: 2,
        nickName: "",
        beginTime: "",
        endTime: "",
      },
      tableUrl: getInfoList,
      headers: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "updateTime",
          label: "最新留言时间",
        },
        {
          prop: "leaveWordContent",
          label: "内容",
          formatter: (row, prop) => {
            if (row[prop] && row[prop] != "") {
              let obj = JSON.parse(row[prop]);
              return obj.text || "-";
            }
          },
        },
      ],
      list: [],
      nowIndex: undefined,
      id: null,
      text: "",
    };
  },
  updated() {
    this.scrollToBottom(); //一进入页面滚动条滚动到底部
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {},
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.bottomBox.scrollIntoView();
      });
    },
    messageDel(item, i) {
      let params = {
        type: 2,
        leaveWordId: item.id,
      };
      this.$axios.post(`${deleteLeaveWordUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.nowIndex = undefined;
          this.getList();
        }
      });
    },
    getList() {
      let params = {
        userId: this.$store.state.app.userInfo.userId,
        tenantId: this.$store.state.app.userInfo.tenantId,
        id: this.id,

        leaveWordType: 2,
      };
      this.$axios
        .get(`${getDetailsListUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.details;
            this.list.forEach((item) => {
              item.leaveWordContent = JSON.parse(item.leaveWordContent);
            });
          }
        });
    },
    rightClick(item, index) {
      this.nowIndex = index;
    },
    confirm() {
      if (this.text == "") {
        this.$message("请输入回复内容~");
        return;
      }
      let obj = {
        text: this.text,
        imgs: "",
      };
      let params = {
        id: this.id,
        userId: this.$store.state.app.userInfo.userId,
        tenantId: this.$store.state.app.userInfo.tenantId,
        leaveWordType: 2,
        leaveWordContent: JSON.stringify(obj),
      };
      this.$axios.post(`${leaveWordsUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.text = "";
          this.getList();
        }
      });
    },
    reply(row) {
      this.title = row.nickName;
      this.id = row.id;
      this.getList();
      this.show = true;
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          type: 1,
          leaveWordId: item.id,
        };
        this.$axios.post(`${deleteLeaveWordUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.divBox {
  height: 100%;
  box-sizing: border-box;
  .box::-webkit-scrollbar {
    display: none;
  }
  .box {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 30px;
    border-bottom: 2px solid #dadada;
    border-top: 2px solid #dadada;
    box-sizing: border-box;
    .item {
      .time {
        color: #333333;
        border-radius: 12px;
        line-height: 28px;
        text-align: center;
      }
      .item-grey {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 32px;
        .content {
          width: 434px;
          padding: 16px 26px;
          background: #f5f5f5;
          box-sizing: border-box;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-radius: 12px;
          line-height: 28px;
          position: relative;
          .text {
            word-break: break-all;
          }
          .imgBox {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .textImg {
              margin-right: 5px;
              width: 120px;
              height: 120px;
              display: block;
            }
          }
          .operation-box {
            position: absolute;
            width: 80px;
            height: 47px;
            background: rgba(0, 0, 0, 0.85);
            bottom: -58px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 12px;
            z-index: 100;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            .line {
              width: 2px;
              height: 16px;
              background: #ffffff;
              margin: 0 20px;
            }
          }
          .arrow {
            position: absolute;
            left: -12px;
            top: 12px;
            width: 15px;
            height: 28px;
          }
          .arrow2 {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -12px;
            width: 24px;
            height: 12px;
          }
        }
        .headImg {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .item-blue {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 32px;
        .content {
          max-width: 434px;
          padding: 16px 26px;
          background: #dbedff;
          box-sizing: border-box;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-radius: 12px;
          line-height: 28px;
          position: relative;
          .text {
            word-break: break-all;
          }
          .operation-box {
            position: absolute;
            width: 166px;
            height: 47px;
            background: rgba(0, 0, 0, 0.85);
            bottom: -58px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 12px;
            z-index: 100;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            .line {
              width: 2px;
              height: 16px;
              background: #ffffff;
              margin: 0 20px;
            }
          }
          .arrow {
            position: absolute;
            right: -12px;
            top: 12px;
            width: 15px;
            height: 28px;
          }
          .arrow2 {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -12px;
            width: 24px;
            height: 12px;
          }
        }
        .headImg {
          margin-left: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  ::v-deep .el-textarea {
    width: 100% !important;
  }
}
</style>
